define([
    'lodash',
    'components/common/mediaCommon/mediaCommon',
    'santa-components',
    'componentsCore'
], function (
    _,
    mediaCommon,
    santaComponents,
    componentsCore
) {
    'use strict';

    const {santaTypesDefinitions} = santaComponents;
    const fillMixin = mediaCommon.mediaLogicMixins.fill;
    const {createChildComponentMixin} = componentsCore.mixins;
    const {scrollMixin, inlineContentMixin} = santaComponents.mixins;
    const {lightboxLayerHelper, pinnedLayerHelper} = santaComponents.utils;

    const getPopupMediaStyle = (isMobileView, siteWidth) => {
        const defaultPopupMediaStyle = mediaCommon.popupMediaStyle;
        //WEED-17313 - force height auto , props.style.height is bigger than content
        return isMobileView ?
            _.defaults({width: `${siteWidth}px`, left: '', right: ''}, defaultPopupMediaStyle) :
            _.defaults({height: 'auto'}, defaultPopupMediaStyle);
    };

    const convertPopupAlignmentToDockedData = popupAlignment => {
        const horizontalAlignmentToDockKey = {
            left: 'left',
            right: 'right',
            center: 'hCenter'
        };
        const verticalAlignmentToDockKey = {
            top: 'top',
            bottom: 'bottom',
            center: 'vCenter'
        };

        if (popupAlignment.alignmentType === 'nineGrid') {
            return {
                [horizontalAlignmentToDockKey[popupAlignment.horizontalAlignment]]: {px: popupAlignment.horizontalOffset},
                [verticalAlignmentToDockKey[popupAlignment.verticalAlignment]]: {px: popupAlignment.verticalOffset}
            };
        }

        if (popupAlignment.alignmentType === 'fullHeight') {
            return {
                top: {px: 0},
                bottom: {px: 0},
                [horizontalAlignmentToDockKey[popupAlignment.horizontalAlignment]]: {px: 0}
            };
        }

        if (popupAlignment.alignmentType === 'fullWidth') {
            return {
                left: {px: 0},
                right: {px: 0},
                [verticalAlignmentToDockKey[popupAlignment.verticalAlignment]]: {px: 0}
            };
        }
    };

    const getPopupChildrenMeshParams = (meshParams, popupAlignment) => {
        const dockedPopup = _.defaults({docked: convertPopupAlignmentToDockedData(popupAlignment)}, meshParams.components[0]);

        return [dockedPopup];
    };

    const page = {
        displayName: 'WixPage',
        mixins: [fillMixin, componentsCore.mixins.skinBasedComp, scrollMixin, createChildComponentMixin, inlineContentMixin],
        propTypes: {
            id: santaComponents.santaTypesDefinitions.Component.id,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
            compDesign: santaComponents.santaTypesDefinitions.Media.Popup.data, // Special compDesign for page with popup
            compStaticBehaviors: santaComponents.santaTypesDefinitions.Component.compStaticBehaviors,
            defaultBackgroundStyle: santaComponents.santaTypesDefinitions.Container.defaultBackgroundStyle.isRequired,
            mediaQuality: santaComponents.santaTypesDefinitions.Media.Popup.mediaQuality, // Special Popup media quality
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice.isRequired,
            isMeshLayoutMechanism: santaComponents.santaTypesDefinitions.Layout.isMeshLayoutMechanism.isRequired,
            meshParams: santaComponents.santaTypesDefinitions.Component.meshParams,
            layoutContainerClassName: santaComponents.santaTypesDefinitions.Layout.layoutContainerClassName,
            closePopupPage: santaComponents.santaTypesDefinitions.popupPage.close,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth.isRequired,
            siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker.isRequired,
            markVisitedPage: santaComponents.santaTypesDefinitions.Page.markVisitedPage,
            renderParts: santaComponents.santaTypesDefinitions.Media.Popup.renderParts, // Special Popup media quality,
            pageStub: santaTypesDefinitions.Component.pageStub,
            pageMinHeightDefault: santaComponents.santaTypesDefinitions.pageMinHeightDefault.isRequired,
            playbackUrl: santaComponents.santaTypesDefinitions.Media.Popup.playbackUrl, // Special Popup media quality
            playbackFormat: santaComponents.santaTypesDefinitions.Media.Popup.playbackFormat, // Special Popup media quality
            playbackConfig: santaComponents.santaTypesDefinitions.Media.Popup.playbackConfig, // Special Popup media quality
            defaultContentArea: santaComponents.santaTypesDefinitions.Container.defaultContentArea.isRequired,
            fixedChildrenIDs: santaTypesDefinitions.Component.fixedChildrenIDs,
            pinnedChildrenIDs: santaTypesDefinitions.Component.pinnedChildrenIDs,
            isPopupPage: santaTypesDefinitions.Page.isPopupPage,
            popupAlignment: santaTypesDefinitions.Page.popupAlignment,
            wixTopAdsHeight: santaTypesDefinitions.WixAds.wixTopAdsHeight,
            isResponsive: santaTypesDefinitions.RendererModel.isResponsive
        },
        statics: {
            behaviors: mediaCommon.mediaBehaviors.fill
        },

        componentDidMount() {
            if (this.props.isPopupPage && this.props.isMobileView && this.props.isMobileDevice) {
                // block site scrolling
                this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, true);
            }
            if (this.props.markVisitedPage) {
                this.props.markVisitedPage(this.props.id);
            }
        },

        componentWillUnmount() {
            if (this.props.isPopupPage && this.props.isMobileView && this.props.isMobileDevice) {
                // enable site scrolling
                this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, false);
            }
        },

        shouldCloseOverlay(viewMode) {
            return _.get(this.props, ['compProp', viewMode, 'popup', 'closeOnOverlayClick']);
        },

        getBalataProps() {
            const viewMode = this.props.isMobileView ? 'mobile' : 'desktop';

            return {
                id: this.props.id,
                onClick: this.shouldCloseOverlay(viewMode) ? this.props.closePopupPage : null,
                bgStyle: getPopupMediaStyle(this.props.isMobileView, this.props.siteWidth),
                mediaQuality: this.props.mediaQuality,
                playbackFormat: this.props.playbackFormat,
                playbackConfig: this.props.playbackConfig,
                playbackUrl: this.props.playbackUrl
            };
        },

        getRootStyle() {
            if (this.props.isPopupPage) {
                return {
                    top: 0,
                    left: 0,
                    position: 'relative'
                };
            }

            return {
                position: 'absolute'
            };
        },

        getSkinProperties() {
            if (this.props.isPopupPage) {
                return this.getPopupPageSkinProperties();
            }
            return this.getPageSkinProperties();
        },

        isMobile() {
            return !!(this.props.isMobileView || this.props.isMobileDevice);
        },

        getPageMinHeight() {
            return _.get(this.props.compProp, [[this.isMobile() ? 'mobile' : 'desktop'], 'minHeight'], 0) || this.props.pageMinHeightDefault;
        },

        getMeshOverridesParams() {
            return {
                minHeight: this.getPageMinHeight(),
                fitToContentHeight: true,
                fitToContentPadding: _.get(this.getSkinExports(), 'paddingBottom', 0),
                width: this.props.siteWidth
            };
        },

        renderChildrenForMesh() {
            const {id, browser, pinnedChildrenIDs, wixTopAdsHeight} = this.props;

            const isReactChildPinned = reactChild => !!pinnedChildrenIDs[reactChild.props.id];
            const [pinnedChildren, notPinnedChildren] = _.partition(this.props.children, isReactChildPinned);

            const isMeshComponentPinned = meshComp => _.get(meshComp, 'isFixed', false) === true && _.has(meshComp, 'docked');
            const pinnedMeshComps = _.filter(this.props.meshParams.components, isMeshComponentPinned);

            return [
                pinnedLayerHelper.createPinnedLayer({id: `${id}-pinned-layer`, children: pinnedChildren, childrenMeshParams: pinnedMeshComps, browser, wixTopAdsHeight}),
                ...this.getChildrenRenderer({overrides: this.getMeshOverridesParams(), contentArea: this.props.defaultContentArea, children: notPinnedChildren})
            ];
        },

        renderChildrenForMeshPopup() {
            const {id, browser, meshParams, popupAlignment} = this.props;

            return [
                lightboxLayerHelper.createLightboxLayer({
                    id,
                    children: this.props.children,
                    childrenMeshParams: getPopupChildrenMeshParams(meshParams, popupAlignment),
                    browser,
                    isFixedLayer: false
                })
            ];
        },

        getPageInlineContentChildren() {
            const {isMeshLayoutMechanism: isMeshLayout, isPopupPage} = this.props;
            if (!isMeshLayout) {
                return this.getChildrenRenderer({overrides: this.getMeshOverridesParams(), contentArea: this.props.defaultContentArea});
            }

            if (isPopupPage) {
                return this.renderChildrenForMeshPopup();
            }

            return this.renderChildrenForMesh();
        },

        getPageSkinProperties(inlineProps) {
            const {isMeshLayoutMechanism: isMeshLayout, isResponsive} = this.props;
            const height = isMeshLayout ? 'auto' : this.props.structure.layout.height;

            const pageRootStyle = _.assign({
                height,
                left: 0
            }, !isMeshLayout ? {
                minWidth: this.props.siteWidth,
                minHeight: this.getPageMinHeight()
            } : {
                height: '100%',
                position: 'relative'
            });

            return {
                '': {
                    'data-ismobile': this.isMobile(),
                    'data-is-mesh-layout': isMeshLayout,
                    style: isResponsive ? {} : pageRootStyle
                },
                bg: {
                    style: this.props.defaultBackgroundStyle
                },
                inlineContent: _.assign(inlineProps, {
                    className: this.props.layoutContainerClassName,
                    children: this.getPageInlineContentChildren()
                })
            };
        },

        getPopupPageSkinProperties() {
            const {isMeshLayoutMechanism: isMeshLayout} = this.props;
            const style = _.assign({
                pointerEvents: 'none',
                minHeight: '100vh',
                minWidth: this.props.siteWidth
            }, this.getRootStyle(), isMeshLayout && {
                position: 'relative',
                height: 'auto',
                display: 'grid'
            });

            const pageSkinProperties = _.omit(this.getPageSkinProperties(), ['.style']);

            return _.merge(pageSkinProperties, {
                '': {
                    style,
                    'data-ispopup': this.props.isPopupPage
                },
                bg: this.createFillLayers(this.getBalataProps())
            });
        }
    };

    componentsCore.compRegistrar
        .register('wixapps.integration.components.AppPage', page)
        .register('mobile.core.components.Page', page);

    return page;
});
