define([
    'lodash',
    'coreUtils',
    'santa-core-utils',
    'componentsCore',
    'santa-components',
    'components/components/bootstrap/menuButton/menuButton',
    'reactDOM',
    'zepto',
    'components/common/translationRequirementsChecker'
], function (
    _,
    coreUtils,
    coreUtilsLib,
    componentsCore,
    santaComponents,
    menuButton,
    reactDOM,
    $,
    translationRequirementsChecker
) {
    'use strict';

    const SUPPORTED_LANGS = ['da', 'de', 'en', 'es', 'fr', 'hi', 'it', 'ja', 'ko', 'nl', 'no', 'pl', 'pt', 'ru', 'sv', 'tr'];

    const OPEN_SUBMENUS_PREVIEW_STATE = 'open';

    function getLanguage(dataLang, cookie, currentUrl) {
        if (dataLang === 'userLang') {
            return coreUtils.wixUserApi.getLanguage(cookie, currentUrl);
        }
        return dataLang;
    }

    function isNumber(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    function shouldPreviewSubMenu(previewState) {
        return _.includes(previewState, OPEN_SUBMENUS_PREVIEW_STATE);
    }

    function getButtonPositionInList(menuPages, dropDown, stretch, buttonAlign, rtl, index) { // eslint-disable-line complexity
        if (index === menuPages.length - 1) {
            if (menuPages.length === 1) {
                return 'dropLonely';
            }
            if (dropDown) {
                return 'bottom';
            }
            if (!stretch && buttonAlign !== 'right') {
                return 'center';
            }
            return rtl ? 'left' : 'right';
        }
        if (index === 0) {
            if (dropDown) {
                return 'top';
            }
            if (!stretch && buttonAlign !== 'left') {
                return 'center';
            }
            return rtl ? 'right' : 'left';
        }
        return dropDown ? 'dropCenter' : 'center';
    }

    function getMenuItemIsSelectedPredicat(currentUrlPageId, openedPopupId, menuPages, highlightAnchorsInMenu, anchorChangeData) {
        const hasLinkToOpenedPopup = _.some(menuPages, ['link.pageId.id', openedPopupId]);
        let activeAnchor;
        const isSelectedMap = {
            'AnchorLink'(link) {
                return (_.has(link.anchorDataId, 'id') ? link.anchorDataId.id : link.anchorDataId) === activeAnchor;
            },
            'PageLink'(link) {
                if (!activeAnchor) {
                    if (link.pageId.isPopup) {
                        return link.pageId.id === openedPopupId;
                    } else if (!hasLinkToOpenedPopup) {
                        return link.pageId.id === currentUrlPageId;
                    }
                }

                return false;
            }
        };

        if (highlightAnchorsInMenu) {
            activeAnchor = coreUtils.menuUtils.getActiveAnchorInPage(anchorChangeData, menuPages, currentUrlPageId);
        }

        return function (link) {
            const isSelectedPredicate = link && isSelectedMap[link.type];

            return isSelectedPredicate ? isSelectedPredicate(link) : false;
        };
    }

    function getMenuBtnState(anchorChangeData, menuPages, dropDown, stretch, buttonAlign, currentUrlPageId, currentPopupId, rtl, highlightAnchorsInMenu) {
        return _.map(menuPages, function (item, index) {
            const link = item.link;
            const isSelected = getMenuItemIsSelectedPredicat(currentUrlPageId, currentPopupId, menuPages, highlightAnchorsInMenu, anchorChangeData);

            return {
                isContainer: Boolean(dropDown),
                isSelected: isSelected(link),
                positionInList: getButtonPositionInList(menuPages, dropDown, stretch, buttonAlign, rtl, index)
            };
        });
    }

    function flattenMenuItemsWithParentId(menuItems) {
        return _.flatMap(menuItems, function (item) {
            let menuChildren = [];
            if (item.items) {
                menuChildren = _.map(item.items, function (subItem) {
                    return _.assign(subItem, {
                        parent: item.id
                    });
                });
            }
            return [item].concat(menuChildren);
        });
    }

    const shouldRenderWixDropdown = ({isExperimentOpen, renderType}) => isExperimentOpen('bv_wixDropdown') && renderType === 'bolt';

    /**
     * @class components.DropDownMenu
     * @extends {core.skinBasedComp}
     * @extends {coreUtilsLib.timersMixins.timeoutsMixin}
     * @extends {componentsCore.skinInfo}
     */
    const dropDownMenu = {
        displayName: 'DropDownMenu',
        propTypes: _.assign({
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            anchorChangeEvent: santaComponents.santaTypesDefinitions.SiteAspects.anchorChangeEvent,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
            currentPopupId: santaComponents.santaTypesDefinitions.currentPopupId,
            highlightAnchorsInMenu: santaComponents.santaTypesDefinitions.BrowserFlags.highlightAnchorsInMenu.isRequired,
            menuItems: santaComponents.santaTypesDefinitions.Menu.menuItems,
            id: santaComponents.santaTypesDefinitions.Component.id,
            refInParent: santaComponents.santaTypesDefinitions.Component.refInParent,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            componentPreviewState: santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired,
            renderType: santaComponents.santaTypesDefinitions.PublicModel.renderType
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(menuButton)),
        mixins: [
            componentsCore.mixins.skinBasedComp,
            coreUtilsLib.timersMixins.timeoutsMixin,
            componentsCore.mixins.skinInfo,
            componentsCore.mixins.createChildComponentMixin
        ],
        getInitialState() {
            this.shouldChildrenUpdate = false;

            return {
                hover: null,
                hoverListPosition: null,
                activeAnchor: null,
                $dropAlign: this.props.compProp.alignButtons,
                $mobile: this.props.isMobileDevice || this.props.isMobileView || this.props.isTabletDevice() ? 'mobile' : 'notMobile'
            };
        },
        componentDidMount() {
            if (this.props.highlightAnchorsInMenu()) {
                this.props.anchorChangeEvent.registerToAnchorChange(this);
            }
        },
        componentWillUnmount() {
            if (this.props.highlightAnchorsInMenu()) {
                this.props.anchorChangeEvent.unregisterToAnchorChange(this);
            }
        },
        convertItemsToChildren(items, childBase, overrideLineHeight, dropDown) {
            childBase = childBase || {};
            childBase.style = childBase.style || {}; // eslint-disable-line santa/no-side-effects

            // TODO when MOBX will work - use this.props.activeAnchorData instead of this.state.activeAnchor
            const cssStates = getMenuBtnState(this.state.activeAnchor, items, dropDown, this.props.compProp.stretchButtonsToMenuWidth, this.props.compProp.alignButtons, this.props.currentUrlPageId, this.props.currentPopupId, this.props.compProp.rtl, this.props.highlightAnchorsInMenu());
            return _.map(items, function (item, index) {
                const ref = (dropDown ? 'moreContainer' : '') + index;
                return this.createChildComponent(item, 'core.components.MenuButton',
                    'repeaterButton',
                    _.merge({
                        isContainer: cssStates[index].isContainer,
                        isSelected: cssStates[index].isSelected,
                        positionInList: cssStates[index].positionInList,
                        id: this.props.id + ref,
                        ref,
                        key: item.id,
                        refInParent: ref,
                        mouseEnterHandler: this.mouseEnterHandler,
                        mouseLeaveHandler: this.mouseLeaveHandler,
                        isDropDownButton: dropDown,
                        onMouseClick: this.onMouseClick,
                        'aria-haspopup': item.items.length > 0,
                        tagName: 'li',
                        direction: this.props.compProp.rtl ? 'rtl' : 'ltr',
                        parentId: item.parent,
                        dataId: item.id
                    }, childBase));
            }.bind(this));
        },
        onMouseClick(event, ref, isSubMenuButton) { // eslint-disable-line complexity
            if (!isSubMenuButton) {
                const subItems = ref !== '__more__' ? this.props.menuItems[ref].items : [];
                const hasChildren = subItems.length > 0 || ref === '__more__';
                const currentDropdownOwnerRef = this.state.hover;
                if (!this.dropDownOpen && hasChildren) {
                    this.mouseEnterHandler(ref);
                    event.preventDefault();
                    event.stopPropagation();
                } else if (this.dropDownOpen && !this.isDropdownOwner(ref, currentDropdownOwnerRef) && hasChildren) {
                    this.mouseLeaveHandler();
                    event.preventDefault();
                    event.stopPropagation();
                    this.mouseEnterHandler(ref);
                } else if (this.dropDownOpen) {
                    this.mouseLeaveHandler();
                }
            } else {
                this.mouseLeaveHandler();
            }
        },

        onAnchorChange(newActiveAnchor) {
            if (newActiveAnchor !== this.state.activeAnchor) {
                this.setState({activeAnchor: newActiveAnchor});
            }
        },

        isDropdownOwner(currentClickRef, openDropdownOwnerRef) {
            return currentClickRef === openDropdownOwnerRef;
        },
        createMoreButton(rtl) {
            const itemId = '__more__';
            let positionInList = rtl ? 'left' : 'right';
            const buttonAlign = this.props.compProp.alignButtons;
            const stretch = this.props.compProp.stretchButtonsToMenuWidth;
            if (!stretch && buttonAlign !== 'right') {
                positionInList = 'center';
            }
            const moreButton = this.createChildComponent({id: itemId, label: this.props.compProp.moreButtonLabel},
                'core.components.MenuButton',
                'repeaterButton',
                {
                    isSelected: false,
                    positionInList,
                    id: this.props.id + itemId,
                    ref: itemId,
                    key: itemId,
                    refInParent: itemId,
                    mouseEnterHandler: this.mouseEnterHandler,
                    mouseLeaveHandler: this.mouseLeaveHandler,
                    onFocus: this.mouseEnterHandler,
                    onBlur: this.mouseLeaveHandler,
                    'aria-haspopup': true,
                    tagName: 'li',
                    onKeyDown: this.subMenuKeyDownHandler,
                    onMouseClick: this.onMouseClick,
                    isDropDownButton: false,
                    display: 'inline-block'
                });
            return moreButton;
        },
        mouseEnterHandler(childId, hoverListPos) {
            this.hovering = true;
            this.lastHovered = this.getCurrentTime();
            const newHoverChildId = childId.replace(this.props.id, '');
            const menuItemsIds = _.keys(this.props.menuItems).concat('__more__');
            const childIndex = menuItemsIds.indexOf(newHoverChildId);
            if (childIndex !== -1 && (isNumber(newHoverChildId) || coreUtils.stringUtils.startsWith(childId, '__')) && childId !== this.state.hover) {
                if (this.state.hover) {
                    this.refs[this.state.hover].setIdleState();
                }
                if (!shouldRenderWixDropdown(this.props)) {
                    this.registerReLayout(); //needed in order to calculate if the hover is opened up/down
                }
                this.setState({hover: childId, hoverListPosition: hoverListPos});
            }
        },
        getCurrentTime() {
            return Date.now();
        },
        mouseLeaveHandler() {
            this.hovering = false;
            this.lastHovered = this.getCurrentTime();
            if (!this.dropDownOpen && this.state.hover) {
                this.refs[this.state.hover].setIdleState();
            }
            this.setTimeout(function () {
                const timeSinceLastHovered = this.getCurrentTime() - this.lastHovered;
                if (!this.hovering && this.state.hover && timeSinceLastHovered >= 1000) {
                    this.refs[this.state.hover].setIdleState();
                    this.dropDownOpen = false;
                    this.setState({hover: null, hoverListPosition: null});
                }
            }.bind(this), 1000);
        },
        getParamsFromSkins() {
            return {
                menuBorderY: this.getSumParamValue('menuTotalBordersY', this.props.skin),
                menuBtnBorder: this.getSumParamValue('menuButtonBorders', this.getSkinExports().repeaterButton.skin),
                ribbonEls: this.getParamFromDefaultSkin('ribbonEls').value ? parseInt(this.getParamFromDefaultSkin('ribbonEls').value, 10) : 0,
                labelPad: this.getFromExports('labelPad'),
                ribbonExtra: this.getFromExports('ribbonExtra') ? Math.abs(parseInt(this.getFromExports('ribbonExtra'), 10)) : 0
            };
        },

        shiftFocusToSubMenu(indexOfTabToFocus) {
            const refOfSubPage = this.refs[`moreContainer${indexOfTabToFocus}`];
            const domNode = reactDOM.findDOMNode(refOfSubPage);
            const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(domNode, true);
            $(_.head(tabbableElements)).focus();
            this.currentFocusedSubMenuIndex = indexOfTabToFocus;
        },

        getFirstIndexOfSubMenu() {
            let buttonDOMNode;
            for (let i = 0; i < this.subMenuItems.length; i++) {
                buttonDOMNode = reactDOM.findDOMNode(this.refs[`moreContainer${i}`]);
                if (window.getComputedStyle(buttonDOMNode).display !== 'none') {
                    return i;
                }
            }
            return 0;
        },

        mainMenuKeyDownHandler(evt) {
            if (this.dropDownOpen) {
                let tabIndexToFocus;
                if (evt.key === 'ArrowDown') {
                    evt.stopPropagation();
                    evt.preventDefault();
                    tabIndexToFocus = this.getFirstIndexOfSubMenu();
                    this.shiftFocusToSubMenu(tabIndexToFocus);
                }
                if (evt.key === 'ArrowUp') {
                    evt.stopPropagation();
                    evt.preventDefault();
                    tabIndexToFocus = this.subMenuItems.length;
                    this.shiftFocusToSubMenu(tabIndexToFocus - 1);
                }
            }
        },

        subMenuKeyDownHandler(evt) { // eslint-disable-line complexity
            let newIndexToFocus;
            let tabbableElements;
            if (evt.key === 'ArrowDown' || !evt.shiftKey && evt.key === 'Tab') { // eslint-disable-line no-mixed-operators
                evt.stopPropagation();
                evt.preventDefault();
                newIndexToFocus = this.currentFocusedSubMenuIndex + 1;
                if (newIndexToFocus === this.subMenuItems.length) {
                    newIndexToFocus = this.getFirstIndexOfSubMenu();
                }
                this.shiftFocusToSubMenu(newIndexToFocus);
            }
            if (evt.key === 'ArrowUp' || evt.shiftKey && evt.key === 'Tab') { // eslint-disable-line no-mixed-operators
                evt.stopPropagation();
                evt.preventDefault();
                newIndexToFocus = this.currentFocusedSubMenuIndex - 1;
                if (newIndexToFocus < 0 || newIndexToFocus < this.getFirstIndexOfSubMenu()) {
                    newIndexToFocus = this.subMenuItems.length - 1;
                }
                this.shiftFocusToSubMenu(newIndexToFocus);
            }
            if (evt.key === 'Escape') {
                evt.stopPropagation();
                evt.preventDefault();
                if (this.state.hover === '__more__') {
                    tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(reactDOM.findDOMNode(this.refs[this.state.hover]));
                } else {
                    tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(this.refs.itemsContainer.children[this.state.hover]);
                }
                _.head(tabbableElements).focus();
                this.setState({hover: null});
            }
        },

        getLanguage() {
            const lang = getLanguage(this.props.compProp.dataLang, this.props.cookie, this.props.currentUrl);
            return _.includes(SUPPORTED_LANGS, lang) ? lang : 'en';
        },

        getAriaLabel() {
            const lang = this.getLanguage();
            const MENU_ARIA_LABEL_KEY = 'mainMenuAriaLabel';
            return coreUtils.translationsLoader.getTranslation('blogTranslations', lang, MENU_ARIA_LABEL_KEY, 'Site navigation');
        },

        createHoverChildren(childrenToConvert) {
            const hoverChildren = this.convertItemsToChildren(childrenToConvert, {
                style: {width: '100%'},
                display: 'block',
                prefix: '_',
                subMenu: true
            }, true, true);

            if (hoverChildren.length > 0) {
                this.dropDownOpen = true;
                this.subMenuItems = hoverChildren;
            }

            return hoverChildren;
        },

        checkMoreVisibilityInPreviewState(moreVisibility, hoverChildren) {
            let firstParentIndex;
            if (this.props.isExperimentOpen('previewSubMenu') && shouldPreviewSubMenu(this.props.componentPreviewState)) {
                moreVisibility = 'inherit';
                const hasChildren = menuItem => menuItem.items.length > 0;
                firstParentIndex = _.findIndex(this.props.menuItems, hasChildren);
                const firsParentPage = _.find(this.props.menuItems, hasChildren);
                const moreItems = this.state.hover === '__more__' ? flattenMenuItemsWithParentId(this.props.menuItems) : [];
                const previewSubMenuItems = firsParentPage ? firsParentPage.items : moreItems;

                if (previewSubMenuItems.length) {
                    hoverChildren = this.createHoverChildren(previewSubMenuItems);
                }

                if (hoverChildren.length > 0) {
                    moreVisibility = 'inherit';
                }
            }

            return {firstParentIndex, hoverChildrenOverride: hoverChildren, moreVisibilityOverride: moreVisibility};
        },

        applyPreviewStateIfNeeded(moreVisibility, hoverChildren) {
            const {firstParentIndex, hoverChildrenOverride, moreVisibilityOverride} = this.checkMoreVisibilityInPreviewState(moreVisibility, hoverChildren);

            const hoverPositionOverride = _.isUndefined(firstParentIndex) ? '' : firstParentIndex;
            let moreContainerHoverIndex;

            if (_.isString(this.state.hover) && !_.isEmpty(this.state.hover)) {
                moreContainerHoverIndex = this.state.hover;
            } else {
                moreContainerHoverIndex = _.isNumber(hoverPositionOverride) ? hoverPositionOverride : null;
            }

            const dropPosition = this.state.hover ? this.state.hoverListPosition : hoverPositionOverride;

            return {dropPosition, moreContainerHoverIndex, hoverChildrenOverride, moreVisibilityOverride};
        },

        getSkinProperties() {
            const paramsFromSkins = this.getParamsFromSkins();
            const children = this.convertItemsToChildren(this.props.menuItems, {display: 'inherit'}, null, null);
            let hoverChildren = [];
            let moreVisibility = 'hidden';
            children.push(this.createMoreButton(this.props.compProp.rtl));
            if (isNumber(this.state.hover) || this.state.hover === '__more__') {
                hoverChildren = this.createHoverChildren(isNumber(this.state.hover) ? this.props.menuItems[this.state.hover].items : flattenMenuItemsWithParentId(this.props.menuItems));
                if (hoverChildren.length > 0) {
                    moreVisibility = 'inherit';
                }
            }

            const {dropPosition, moreVisibilityOverride, moreContainerHoverIndex, hoverChildrenOverride} = this.applyPreviewStateIfNeeded(moreVisibility, hoverChildren);

            return {
                '': {
                    id: this.props.id,
                    key: this.props.refInParent,
                    className: 'hidden-during-prewarmup',
                    style: {
                        'overflowX': 'hidden'
                    },
                    'data-stretch-buttons-to-menu-width': !!this.props.compProp.stretchButtonsToMenuWidth,
                    'data-same-width-buttons': !!this.props.compProp.sameWidthButtons,
                    'data-num-items': _.size(this.props.menuItems),
                    'data-menuborder-y': paramsFromSkins.menuBorderY,
                    'data-menubtn-border': paramsFromSkins.menuBtnBorder,
                    'data-ribbon-els': paramsFromSkins.ribbonEls,
                    'data-label-pad': paramsFromSkins.labelPad,
                    'data-ribbon-extra': paramsFromSkins.ribbonExtra,
                    'data-drophposition': dropPosition,
                    'data-hovered-item': this.state.hover,
                    'data-dropalign': this.props.compProp.alignButtons,
                    dir: this.props.compProp.rtl ? 'rtl' : 'ltr',
                    tagName: shouldRenderWixDropdown(this.props) ? 'wix-dropdown-menu' : 'div'
                },
                'itemsContainer': {
                    children,
                    style: {
                        textAlign: this.props.compProp.alignButtons
                    },
                    onKeyDown: this.mainMenuKeyDownHandler,
                    'aria-label': this.getAriaLabel(),
                    role: 'navigation'
                },
                'moreContainer': {
                    onKeyDown: this.subMenuKeyDownHandler,
                    children: hoverChildrenOverride,
                    'data-hover': moreContainerHoverIndex,
                    style: {visibility: moreVisibilityOverride},
                    id: `${this.props.id}moreContainer`
                },
                'dropWrapper': {
                    style: {visibility: moreVisibilityOverride},
                    'data-drophposition': dropPosition,
                    'data-dropalign': this.props.compProp.alignButtons
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.menus.DropDownMenu', dropDownMenu);
    translationRequirementsChecker.registerCommonLanguageRequirement(
        'wysiwyg.viewer.components.menus.DropDownMenu',
        (siteData, compInfo) => _.get(compInfo, ['properties', 'dataLang']));

    return dropDownMenu;
});
